import React, { Component } from 'react'
import donate from '../images/z_movie.mp4'
// import donate1 from '../images/maruza2.mp4'
import '../styles/video.css'

class video extends Component {
  render() {
    return (
      <div className="container">
      <div className='video_content'>
      <h1 className='video_title'>/ О нас</h1>
      <h3 className='video_cl'> /Последние новости</h3>
      {/* <span className='text_news'>ХОРОШАЯ ЖЕНЩИНА УЛУЧШАЕТ МИР</span> */}
      <p className='video_text'>
      Благодаря этому видео вы сможете узнать всю интересующую вас информацию о Зангиоте и истории мемориального комплекса, построенного его именем.</p>
      <video  className='video_mosque' controls >
      <source src={donate} type="video/mp4"/>
     </video>
     { <p className='video_text' > <span className='txt_abror'>Суфизм это молится так, как будто ты видел Бога</span></p>
     }
     {<p>Представьте себе такую ​​цепочку: она продолжается непрерывно со времен Посланника Аллаха, да благословит его Аллах и приветствует. Можно ли сказать, что неправильный путь тех, кто крепко держится за звенья этой цепи, кто доказал, что они незаменимые слуги Бога, религии, страны и народа?
<p>Нет, совсем нет!</p>
Сегодня, к сожалению, среди нас есть те, кто отрицает суфизм. Такие невежественные люди отрицают пути и линии Пророка, мир ему, из-за своего невежества. Потому что незнание бросает человека в пропасть.
<p>Есть пословица: «То, чего человек не знает, — его враг». Действительно, оказывается, что те, кто сегодня отрицает суфизм, ничего о нем не знают.</p>
<p>Происхождение и определение слов «суфизм» и «суфий» различны, и признанными являются следующие:</p>
Слово суфий происходит от арабского слова «суф» — «шерсть». Большинство людей, отданных на молитву, также носили очень простую одежду. Обычно их одежда была только из шерсти. Поскольку шерсть не мягкая, она во все века считалась особой одеждой древних. Что касается памятников Пророку, да благословит его Господь и дарует ему мир, сообщается, что он носил шерстяную одежду.
<p>Абу Сулейман Дорани говорит по этому поводу следующее: «Шерсть – один из признаков аскетизма. Тот, кто хочет носить шерсть стоимостью пять дирхамов, не должен носить шерсть стоимостью три дирхама».
Термин «суфизм» также возник в отношении аскетов и подвижников, носящих шерстяную одежду.</p>
Габриэль, мир ему, спросил Посланника Аллаха, да благословит его Бог и дарует ему мир: «Что такое Ихсан?» они спросили. Он, да благословит его Бог и дарует ему мир, сказал: «Не молись Аллаху так, как будто ты видишь Его, если ты не видишь Его, как будто Он видит тебя».
<p>Это наставление стало главным девизом суфизма.</p>
Суфизм – это очищение души, красивое поведение, приведение сердца в состояние, когда оно не любит ничего, кроме любви к Аллаху, и пребывание в этом статусе. Также в суфизме существует строгое соблюдение обязательных, обязательных и запретных практик. Условие – каждая работа должна выполняться с искренностью и искренностью, как бы видя Аллаха Всевышнего. Слова «тарикат», «пиявка», «тазкиятун нафс» являются синонимами суфизма.
<p>Хасан Басри, да благословит его Аллах и дарует ему мир, говорит: «Науку суфизма мы приобрели не собирая разнообразную информацию, а оставив мир и его удовольствия».
</p>
<p>Шейх Абу Талиб Макки говорит: «Эти две науки связаны друг с другом так же, как ислам и вера связаны друг с другом. Как тело и душа неразделимы, так и разделены они не могут. Если он будет разделен, он потеряет свое значение».
</p><p>Имам Малик, да благословит его Бог и дарует ему мир, говорит: «Кто изучает юриспруденцию, но не знает суфизма, тот допускает ошибки и недостатки в своих действиях. Кто знает суфизм, но не изучает юриспруденцию, тот заблудится. Если он объединит то и другое, он достигнет истины, и его действия будут приемлемыми».
«Что такое суфизм?» Когда его спросили об этом, он сказал: «Благородный человек, находящийся с благороднейшими людьми в лучший период, — это появляющаяся прекрасная нравственность».
</p>В суфизме есть несколько известных направлений, их еще называют сектами. Они названы в честь шейха, основавшего эту секту. Наиболее популярными сектами являются Рифайя, Шазилия, Кадирийа, Мавлавия, Яссавия и Накшбандия. Секты Яссавия и Накшбанди широко распространены в Центральноазиатском регионе. В частности, в нашей стране преследуют секту Накшбанди. Хотя секты в некоторых аспектах отличаются друг от друга, они соответствуют шариату.
Несправедливо отрицать такой замечательный путь. Не дай Бог, отрицание суфизма означает отрицание Сунны. Последствия отказа от обрезания очень плохи.
<p className='domla_shokir'>Шокиржан домла</p></p> }
     {/* <video className='video_mosque' controls >
            <source src={donate1} type="video/mp4" />
          </video> */}
      </div>
      </div>
    );
  }   
}

export default video;